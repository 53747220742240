import React, { Component } from 'react'

export default class EventDetails extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        emptyImage: 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541'
      }
    }

    renderEventIncludedElements = (included) => {
        return included.map((element, index) => {
            return (
                <div className='element' key={index}>
                    <div className='icon-container'>
                        <div className={'icon ' + element?.type} />
                    </div>
                    <p>{element?.value}</p>
                </div>
            )
        })
    }

    renderMoreDetails = (event) =>  {
        return (
            <div className='more-details'>

                <div className='detail'>
                    <div className='subtitle'>
                        Date
                    </div>
                    <div className='value'>
                        {event?.date}
                    </div>
                </div>

                <div className='detail'>
                    <div className='subtitle'>
                        Time
                    </div>
                    <div className='value'>
                        {event?.time}
                    </div>
                </div>

                <div className='detail'>
                    <div className='subtitle'>
                        Duration
                    </div>
                    <div className='value'>
                        {event?.duration}
                    </div>
                </div>

            </div>
        )
    }

    getBtnValue = (status) => {
        if (status === 'available')
            return 'register now'
        
        return status
    }
    renderEventLink = (event) => {
        if(event?.link)
            return(
                <div className='event-link'>
                    <h3 className='subtitle'>
                        Event Link
                    </h3>
                    <a href={event?.link} target="_blank">
                        {event?.link}
                    </a>
                </div>
            )
    }

    renderEventDetails = (element) => {
        let event = element?.event;

        return (
            <div className='content-container event'>
                <div className='title'>
                    {element?.title || ''}
                </div>
                <div className='content'>
                    {element?.content || ''}
                </div>
                {this.renderMoreDetails(event)}
                {this.renderEventLink(event)}
                <div className={'register-btn ' + event?.status}>
                    {this.getBtnValue(event?.status)}
                </div>
            </div>
        )
    }

    renderOrganiserDetails = (organiser) => {
        return (
            <div className='content-container event organiser'>
                <div className='organiser-container'>
                    <div className='header-organiser'>
                        <img src={organiser?.image || this.state.emptyImage} className='profile-picture' />

                        <div className='name-container'>
                            <h3>
                                {organiser?.name}
                            </h3>
                            <p>
                                {organiser?.handle}
                            </p>
                        </div>

                    </div>

                    <div className='description'>
                        {organiser?.info}
                    </div>

                    {this.renderSocials(organiser?.socials)}

                </div>
            </div>
        )
    }

    renderSocials = (socials) => {
        if (!socials?.length) return null;

        return (
            <div className='social-media'>
                <h3>Follow me on :</h3>
                <div className='socials'>
                    {
                        socials.map((social, index) => {
                            let icon;
                            switch (social?.type) {
                                case 'facebook':
                                    icon = 'bx bxl-facebook-circle';
                                    break;
                                case 'twitter':
                                    icon = 'bx bxl-twitter';
                                    break;
                                case 'linkedin':
                                    icon = 'bx bxl-linkedin-square'
                                    break;
                            }

                            return <a href={social?.url || '#'} className={icon} key={index}/>
                            
                        })
                    }
                </div>
            </div>
        )
    }

    switchRenderEvent = (element) => {
        if (this.props.isOrganiserClicked)
            return this.renderOrganiserDetails(element?.event?.organiser)
        else
            return this.renderEventDetails(element)
    }

    render() {
        return this.switchRenderEvent(this.props.element)
    }
}
