import React, { Component } from 'react'
import { Link } from 'gatsby-plugin-react-i18next';
import '../../styles/scss/modal.scss'
import EventDetails from './EventDetails';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default class Modal extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        isOrganiserClicked: false, // only for events
      }

      this.modalRef = React.createRef();
    }

    componentDidMount = () => {
        window.addEventListener('mousedown', this.pageClick, false);
        this.disableScroll()
    }

    componentWillUnmount = () => {
        clearAllBodyScrollLocks();
    }

    disableScroll = () => {
        let body = document.querySelector('#targetElementId');
        disableBodyScroll(body);
    }

    pageClick = (e) => {
        if (this.modalRef && this.modalRef.current && !this.modalRef.current.contains(e.target)) {
            this.closeModal()
        }
    }

    closeModal = () => {
        this.props.closeModal && this.props.closeModal()
    }

    renderGallery = (gallery) => {
        if (gallery?.length)
            return (
                <div className='gallery-container'>
                    <div className='elements'>
                       {gallery.map((element, index) => {
                            return <div className='element' key={index} style={{backgroundImage: `url(${element?.src || element?.source})`}} onClick={() => this.selectImage(element?.src || element?.source)}/>
                       })}
                    </div>
                </div>
            )
    }

    renderContent = (element) => {
        return (
            <div className='content-container'>
                <div className='title'>
                    {element?.title || ''}
                </div>
                <div className='content'>
                    {element?.content || ''}
                </div>
            </div>
        )
    }

    switchRender = (element) => {
        if (element?.event)
            return <EventDetails element={element} isOrganiserClicked={this.state.isOrganiserClicked}/>
        
        return this.renderContent(element)
    }

    // not ideal but clearer when reading
    renderProfilePicture = (organiser) => {
        if (this.state?.isOrganiserClicked) {
            return <div className='profile-picture back'/>
        }
        else if (organiser?.image && !this.state?.isOrganiserClicked) {
            let image = organiser?.image 
            return <div className='profile-picture' style={{backgroundImage: `url(${image})`}}/>
        }

        return <div className='profile-picture' style={{backgroundImage: ''}}/>
    }

    renderOrganiser = (organiser) => {
        if (!organiser) return null;

            return (
                <div className='organiser-container'>
                    <div className='organiser' onClick={this.toggleOrganiserClicked} >
                        {
                            this.renderProfilePicture(organiser)
                        }
                        <p>
                            Organiser
                        </p>
                    </div>
                </div>
            )
    }

    toggleOrganiserClicked = () => {
        this.setState({
            isOrganiserClicked: !this.state.isOrganiserClicked
        })
    }

    selectImage = (src) => {
        this.setState({
            selectedImage: src
        })
    }

    render() {
        const element = this.props.selectedElement;
        return (
            <div className='modal-overlay'>
                <div className='modal-container'>
                    <div className={'modal-motion' + (this.props.isPossibilities ? ' possibilities' : '')} ref={this.modalRef}>
                        <div className='image-container'>
                            <div className={'image ' + element?.class} style={{backgroundImage: `url(${this.state.selectedImage || element?.source})`, ...element?.styleModal}}>
                                {this.renderGallery(element?.gallery)}
                                {
                                    this.renderOrganiser(element?.event?.organiser)
                                }
                            </div>    
                        </div>
                        {
                            this.switchRender(element)
                        }
                        <div className='close-modal' onClick={this.closeModal}>
                            <div/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}