import React, { Component } from 'react'
import RouletteEdit from '../RouletteEdit'

export default class Scaning3DEdit extends Component {
    constructor(props) {
        super(props)

        this.state = {
            page: {
                name: 'scanning-3D',
                title: {
                    text: '3D',
                },
                subtitle: {
                    text: 'Scanning',
                },
                gallery: [
                    {
                        source: 'https://versecorporatestorage.blob.core.windows.net/verseblob/Body_Scanner.png',
                        title: 'Photogrammetry body scanner',
                        content: `
                        Used to create 3D digital models of the human body. It works by capturing multiple images of a person from different angles and using software to stitch them together into a single 3D model.
                        `,
                        isContact: true,
                        possibilities: [
                            {
                                src: 'https://versecorporatestorage.blob.core.windows.net/verseblob/photogrammetry_body_scanner_-_possibilities.jpg',
                                path: ''
                            },
                            {
                                src: 'https://versecorporatestorage.blob.core.windows.net/verseblob/photogrammetry_body_scanner_-_possibilities_2.jpg',
                                path: ''
                            },
                            {
                                src: 'https://versecorporatestorage.blob.core.windows.net/verseblob/photogrammetry_body_scanner_-_possibilities_3.jpg',
                                path: ''
                            },
                            {
                                src: 'https://versecorporatestorage.blob.core.windows.net/verseblob/photogrammetry_body_scanner_-_possibilities_4.jpg',
                                path: ''
                            }
                        ]
                    },
                    {
                        source: 'https://versecorporatestorage.blob.core.windows.net/verseblob/3d_object_main.png',
                        title: '3D Object Scanner',
                        content: `
                        Used to create digital representations of physical objects. Gives the ability to create high-quality models in a relatively short amount of time
                        `,
                        isContact: true,
                        possibilities: [
                            {
                                
                                src: 'https://versecorporatestorage.blob.core.windows.net/verseblob/3d_object_scanner_possibilities_1.png?1680100911',
                                path: ''
                            },
                            {
                                src: 'https://versecorporatestorage.blob.core.windows.net/verseblob/3d_object_scanner_possibilities_2.png?1680100911',
                                path: ''
                            },
                            {
                                src: 'https://versecorporatestorage.blob.core.windows.net/verseblob/3d_object_scanner_possibilities_3.png?1680100911',
                                path: ''
                            },
                            {
                                src: 'https://versecorporatestorage.blob.core.windows.net/verseblob/3d_object_scanner_possibilities_4.png?1680100911',
                                path: ''
                            }
                        ]
                    },
                ]
            }
        }
    }

    render() {
        return <RouletteEdit
            page={this.state.page}
        />
    }
}
