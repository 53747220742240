import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import '../../styles/fonts.css'
import ARMRVR from '../../components/ARMRVR'
import LivePerformance from '../../components/LivePerformance'
import Motion from '../../components/Edit/interfaces/Motion'
import GamesEdit from '../../components/Edit/interfaces/GamesEdit'
import Join from '../../components/Join'
import Hero from '../../components/3D/Hero'
import Footer from '../../components/Common/Footer'
import Header from '../../components/Common/Header'
import SEO from '../../components/Common/SEO'
import About from '../../components/Edit/interfaces/About'
import Modeling3DEdit from '../../components/Edit/interfaces/Modeling3DEdit'
import Scaning3DEdit from '../../components/Edit/interfaces/Scaning3DEdit'

export default class Index extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isTransparent: true,
            isLoaded: false
        }
        this.myRef = React.createRef();
    }

    componentDidMount = () => {
        this.setState({
            isLoaded: true
        })

        if (!JSON.parse(localStorage.getItem('user')) || !this.isTokenValid()) {
            window.open('/', '_self')
        }
    }

    isTokenValid = () => {
        const token = this.getCurrentToken();

        if (!token || token === undefined)
            return false;
        else if (this.parseJwt(token).exp < Date.now() / 1000) {
            localStorage.clear();
            return false;
        }
        return true;
    }

    getCurrentToken = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.accessToken)
            return user.accessToken;
    }

    parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return {exp: 0};
        }
    };

    handleScroll = (event) => {
        if (typeof window !== 'undefined') {
            const node = this.myRef?.current;
            if (node && window && window.innerWidth > 768) {
                let isTransparent = node.scrollTop < window.innerHeight - 5;
                if ((isTransparent && !this.state.isTransparent) || (!isTransparent && this.state.isTransparent))
                    this.setState({
                        isTransparent: isTransparent,
                    });
            }
        }
    }

    render() {
        return (
            <div id="scrollable" onScroll={(e) => this.handleScroll(e)} ref={this.myRef}>
                <SEO 
                    title={'Verse Estate - Home page'}
                    description={'Welcome to the Verse Estate website'}
                />

                <Header isTransparent={this.state.isTransparent} isLoaded={this.state.isLoaded}/>
                <div className='snap'>
                    <Hero />
                </div>
                <div className='snap height-fixed-150'>
                    <Scaning3DEdit />
                </div>
                <div className='snap end'>
                    <ARMRVR />
                </div>
                <div className='snap height-fixed-150'>
                    <Modeling3DEdit />
                </div>
                <div className='snap'>
                    <Motion />
                </div>
                <div className='snap'>
                    <LivePerformance />
                </div>
                <div className='snap'>
                    <GamesEdit />
                </div>
                <div className='snap'>
                    <About />
                </div>
                <div className='snap'>
                    <Join />
                </div>
                <Footer />
            </div>
        )
    }
}
