import React, { Component } from 'react'
import { Link } from 'gatsby-plugin-react-i18next';
import '../../styles/scss/modal.scss'
import EventDetails from './EventDetails';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default class Possibility extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        index: 0,
        selectedElement: null
      }

      this.modalRef = React.createRef();
    }

    componentDidMount = () => {
        window.addEventListener('mousedown', this.pageClick, false);
        this.disableScroll();
        let initialIndex=this.props.selectedElement?.index;
        this.setState({
            index: initialIndex,
            selectedElement: this.props.selectedElement?.gallery[initialIndex]
        })
    }

    componentWillUnmount = () => {
        clearAllBodyScrollLocks();
    }

    disableScroll = () => {
        let body = document.querySelector('#targetElementId');
        disableBodyScroll(body);
    }

    pageClick = (e) => {
        if (this.modalRef && this.modalRef.current && !this.modalRef.current.contains(e.target)) {
            this.closeModal()
        }
    }

    closeModal = () => {
        this.props.closeModal && this.props.closeModal()
    }

    navigate = (value) => {
        if (!this.props.selectedElement?.gallery?.length) return;

        let index = this.state.index;
        index += value;

        if (index < this.props.selectedElement.gallery?.length && index > 0) {
        } else index = 0
        
        this.setState({
            selectedElement: this.props.selectedElement?.gallery[index],
            index: index
        })
    }

    render() {
        const element = this.state.selectedElement;
        return (
            <div className='modal-overlay'>
                <div className='modal-container'>
                    <div className='modal-possibilities' ref={this.modalRef}>
                        <div className='arrow left' onClick={() => this.navigate(-1)}>
                            <i class='bx bx-chevron-left'></i>
                        </div>
                        <div className='image' style={{backgroundImage: `url(${element?.src})`}}/>
                        <div className='arrow right' onClick={() => this.navigate(+1)}>
                            <i class='bx bx-chevron-right'></i>
                        </div>
                        <div className='numbers'>
                            <div className='number'>
                                {this.state.index + 1} / {this.props.selectedElement?.gallery?.length}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}