import { Link } from 'gatsby-plugin-react-i18next';
import React, { Component } from 'react'
import '../../styles/scss/roulette.scss'
import Modal from '../Common/Modal';
import Possibility from '../Common/Possiblity';
import EditImage from './EditImage';
import axios from 'axios';
import authHeader from '../../../service/auth-header';
import PossibilityEdit from './PossibilityEdit';
import PublishMenu from './PublishMenu';

export default class RouletteEdit extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isBackArrowActive:false,
            isNextArrowActive:true,
            selectedElement: {
                index: 0
            }
        }
    }

    componentDidMount() {
        this.setState({
            page: this.props.page
        }, () => {
            this.selectElement(undefined, 0);
            this.updatePageData()
        });
    }

    updatePageData = () => {
        axios.get(`https://versemail.azurewebsites.net/page/${this.props.page?.name}`).then(response => {
            if (response?.data) {
                this.setState({
                    page: {
                        ...this.state.page,
                        ...response?.data
                    },
                    originalPage: {...response?.data}
                }, () => this.selectElement(undefined, this.state.selectedElement?.index))
            }
        }).catch((error) => {
            console.log(error.response)
        });
    }

    revertPage = () => {
        this.setState({
            page: this.state.originalPage
        }, () => {
            // this.publishPage(this.state.page?.name, this.state.page)
        })
    }

    handleGalleryInputChange = (e, index) => {
        let selectedElement = this.state.selectedElement,
        gallery =  this.state.page?.gallery.map((element, i) => {
            if (element && i === index) {
                element = {
                    ...element, 
                    [e.target.name]: e.target.value
                }
                selectedElement = {...element, index}
            }
            
            return element
        })

        this.setState({
            page: {
                ...this.state.page,
                gallery: gallery
            },
            selectedElement: selectedElement 
        })
    }

    updatePossibility = (src, index, indexPossibility) => {
        let selectedElement = this.state.selectedElement,
        selectedPossibility = this.state.selectedPossibility,
        gallery =  this.state.page?.gallery.map((element, i) => {
            if (element && i === index) {
                if (element?.possibilities?.length) {

                    element.possibilities = element.possibilities.map((possibility, index) => {
                        if (index === indexPossibility) {
                            console.log('found it')
                            possibility.src = src
                        }

                        selectedPossibility = {...possibility, index}
                        return possibility
                    })

                    selectedElement = {...element, index}
                }
            }
            
            return element
        })

        this.setState({
            page: {
                ...this.state.page,
                gallery: gallery
            },
            selectedElement: selectedElement,
            selectedPossibility: selectedPossibility
        })
    }

    selectElement = (element, index, isFromInteraction) => {
        // if we don't specify element and only index, we search the elment
        if (!element && index !== undefined) {
            let elements = this.state.page?.gallery;
            if (!elements?.length) return;

            let i = 0;
            for (let e of elements) {
                if (i === index) {
                    element = e;
                    break;
                }
                i++
            }
        }

        this.setState({
            selectedElement: {
                ...element,
                index
            },
        })

        if (isFromInteraction && this.loop) {
            clearInterval(this.loop)
        }
    }

    navigate = (index) => {
        if(index > -1 && index < this.state.page?.gallery.length){
            let element = this.state.page?.gallery[index];
            this.setState({
                selectedElement:null
            }, () => {
                this.setState({
                    isNextArrowActive: (index < this.state.page?.gallery.length-1),
                    isBackArrowActive: (index > 0),
                    selectedElement: {
                        ...element,
                        index
                    }
                })
            })

            if (this.loop) {
                clearInterval(this.loop)
            }
        }
    }


    /**
     * Should have been done with css selectors but this was made to avoid unwanted behavior in different browsers
     * @param {*} index 
     * @returns 
     */
    getOptionClass = (index) => {
        switch (index) {
            case 0:
                return 'first'
            case 1:
                return 'second'
            case 2:
                return 'third';
            default:
                return ''
        }
    }

    isActive = (index) => {
        if (index === this.state.selectedElement?.index)
            return ' active'

        return ''
    }

    renderOptions = (gallery) => {
        if (!gallery?.length) return;

        return gallery.map((element, index) => {
            return (
                <div className={'option ' + this.getOptionClass(index) + this.isActive(index)} onClick={() => this.selectElement(element, index, true)}>
                    <div className='color-layer' />
                    <div className='text'>
                        {element?.title}
                    </div>

                    <div className='scanner-dot' />
                </div>
            )
        })
    }

    renderAddOption = (index = 2) => {
        if (this.state.page?.gallery?.length < 3)
            return (
                <div className={'option ' + this.getOptionClass(index) + this.isActive(index)} onClick={() => this.addOption()}>
                    <div className='color-layer' />
                    <div className='text' style={{fontSize: "35px"}}>
                        +
                    </div>

                    <div className='scanner-dot' />
                </div>
            )
    }

    addOption = () => {
        let gallery = this.state.page?.gallery;
        if (!gallery?.length || gallery?.length >= 3) return;
        
        let option = {
            index: gallery?.length ,
            source: 'https://media.istockphoto.com/id/1128826884/vector/no-image-vector-symbol-missing-available-icon-no-gallery-for-this-moment.jpg?s=170667a&w=0&k=20&c=O9Y41QO7idN44o-VK5s7dBUqg-dhJZcyagMb8485BNU=',
            title: '',
            content: `
            `,
            isContact: true,
            possibilities: [
                {
                    src: '.',
                    path: ''
                },
                {
                    src: ".",
                    path: ''
                },
                {
                    src: '.',
                    path: ''
                },
                {
                    src: '.',
                    path: ''
                }
            ]
        };
        gallery.push(option);

        this.setState({
            page: {
                ...this.state.page,
                gallery: gallery
            },
            selectedElement: option 
        })
    }

    renderWatchVideo = (isContact) => {
        if (!isContact)
            return (
                <a href='#' className='watch-video'>
                    <i class='bx bxl-youtube'></i>
                    Watch the video
                </a>
            )

        return (
            <Link to={"/contact"} className='contact-us-btn'>
                contact us
            </Link>
        )
    }

    renderPossibilities = (possibilities) => {
        if (!possibilities?.length) return null;

        return possibilities.map((possibility,index) => {
            return <div style={{ backgroundImage: `url(${possibility?.src})` }}  onClick={() => this.selectPossibility(possibility,index)} />
        })
    }

    renderModal = () => {
        if (this.state.selectedPossibility)
            return <PossibilityEdit
                selectedElement={this.state.selectedPossibility}
                closeModal={this.selectPossibility}
                updatePossibility={this.updatePossibility}
                indexElement={this.state.selectedElement?.index}
            />
    }

    selectPossibility = (possibility,i) => {
        if (!possibility) return this.setState({selectedPossibility: possibility})
        this.setState({
            selectedPossibility: {
                title: 'Possibilities',
                ...possibility,
                source: possibility?.src,
                index:i,
                gallery: [...this.state.selectedElement?.possibilities]
            }
        })
        if (this.loop) {
            clearInterval(this.loop)
        }
    }

    renderNavigation = () => {
        if(this.state.page?.gallery.length > 1){
            return(
                <div className='navigation-controls'>
                    <span className='back arrow' style={{opacity:this.state.isBackArrowActive && 1}}  onClick={() => {
                        this.navigate(this.state.selectedElement.index-1)
                    }}></span>
                    <span className='indicator'> 
                        {`${this.state.selectedElement?.index + 1 + " /" + this.state.page?.gallery.length}`}
                    </span>
                    <span className='next arrow' style={{opacity:this.state.isNextArrowActive || 0}} onClick={() => {
                        this.navigate(this.state.selectedElement.index+1)
                    }}></span>
                </div>
            )
        }
    }

    handleContentChange = (e, index) => {
        let event = {
            target: {
                name: e.target.name,
                value: this.state.contentWritten || e.target.value
            }
        }

        this.handleGalleryInputChange(event, index)
    }

    renderSelectedElement = (selectedElement) => {
        return (
            <>
                <div className='roulette-target'>
                    {
                        selectedElement?.source &&
                            <div 
                                className='illustration edit' 
                                key={selectedElement?.source} 
                                style={{ backgroundImage: `url(${selectedElement?.source})` }} 
                                onClick={() => this.toggleSelectImage(selectedElement?.source)}
                            />
                    }
                    {this.renderNavigation()}
                </div>

                <div className='content-container left'>
                    <h4>Possibilities</h4>
                    <div className='possibilities-scroll'>
                        <div className='possibilities'>
                            {this.renderPossibilities(selectedElement?.possibilities)}
                        </div>
                    </div>
                </div>
                <div className='content-container right edit'>
                    <input
                        type='text'
                        className={'title' + (this.state.selectedElement?.type === 'title' ? ' active' : '')}
                        value={selectedElement?.title}
                        onChange={(e) => this.handleGalleryInputChange(e, selectedElement?.index)}
                        style={{ ...this.state.page?.title?.style }}
                        name='title'
                        placeholder='Title'
                        autoComplete="off"
                    />

                    <div
                        contentEditable='true'
                        onInput={e => this.contentOnInput(e)}
                        onBlur={(e) => this.handleContentChange({target:{value: e.target.value, name: 'content'}}, this.state.selectedElement?.index)}
                        className='text'
                    >{selectedElement?.content || ''}</div>

                    {
                        this.renderWatchVideo(selectedElement?.isContact)
                    }
                </div>
            </>
        )
    }

    selectImage = (src) => {
        this.setState({
            selectedImage: src
        })
    }

    toggleSelectImage = (src = '') => {
        this.setState({
            selectedImageUpdate: {src}
        })
    }

    updateImage = (image) => {
        let event = {
            target: {
                name: 'source',
                value: image
            }
        }

        this.handleGalleryInputChange(event, this.state.selectedElement?.index)
        this.selectImage(image)
    }

    contentOnInput = (e) => {
        this.setState({
            contentWritten: e.currentTarget.textContent
        })
    }

    handleInputChange = (e) => {
        this.setState({
            page: {
                ...this.state.page,
                [e.target?.name]: {
                    ...this.state.page[e.target?.name],
                    text: e.target.value
                }
            }
        })
    }

    render() {
        return (
            <div className='modeling-3d' id={this.state.page?.name}>
                <EditImage
                    selectedImage={this.state.selectedImageUpdate} 
                    toggleSelectImage={this.toggleSelectImage}
                    updateImage={this.updateImage}
                />

                <div className='titles-container edit'>
                        <PublishMenu
                            revertPage={this.revertPage}
                            page={this.state.page}
                            originalPage={this.state.originalPage}
                        />
                        <input
                            type='text'
                            className={'title' + (this.state.selectedElement?.type === 'title' ? ' active' : '' + ' h2')}
                            value={this.state.page?.title?.text}
                            style={{ ...this.state.page?.title?.style }}
                            name='title'
                            placeholder='Title'
                            onChange={(e) => this.handleInputChange(e)}
                            autoComplete="off"
                        />
                        <input
                            type='text'
                            className={'subtitle' + (this.state.selectedElement?.type === 'subtitle' ? ' active' : '' + ' h3')}
                            value={this.state.page?.subtitle?.text}
                            style={this.state.page?.subtitle?.style}
                            name='subtitle'
                            placeholder='Subtitle'
                            onChange={(e) => this.handleInputChange(e)}
                            autoComplete="off"
                        />
                </div>

                <div className='content-menu'>
                    <div className='roulette'>
                        <div className='roulette-main'>
                            <div className='rectangle'>
                                {this.renderOptions(this.state.page?.gallery)}
                                {this.renderAddOption()}
                                {this.renderSelectedElement(this.state.selectedElement)}
                            </div>
                        </div>
                    </div>
                </div>

                {this.renderModal()}
            </div>
        )
    }
}
